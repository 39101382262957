import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  FETCH_SETTING_COMMIT,
  FETCH_SETTING_REQUEST,
  FETCH_SETTING_ROLLBACK,
  UPDATE_USER_SETTING_COMMIT,
  UPDATE_USER_SETTING_ROLLBACK,
} from '../actions/setting';
import { Setting } from '../../../models/Settings';
import { PhoneLinePreference } from '../../../models/Types';

interface SettingAction {
  type: string;
  payload: string;
}

export interface SettingState {
  settings: Setting[];
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const initialStates: SettingState = {
  settings: [],
  isFetching: false,
  hasError: false,
};

const setting: Reducer<SettingState, SettingAction> = (
  state = initialStates,
  action
) => {
  switch (action.type) {
    case FETCH_SETTING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UPDATE_USER_SETTING_COMMIT: {
      const newSetting = action.payload as unknown as Setting;
      const updatedSettings = state.settings.map((setting) => {
        if (setting.key === newSetting.key) {
          return newSetting;
        }
        return setting;
      });
      return {
        ...state,
        settings: updatedSettings,
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_SETTING_COMMIT: {
      return {
        ...state,
        settings: action.payload as unknown as Setting[],
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_SETTING_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchSettingsError',
      };
    }
    case UPDATE_USER_SETTING_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.updateSettingsError',
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialStates;
    }
    default:
      return state;
  }
};
export default setting;

export const getIsSettingEnabled = (
  state: SettingState,
  key: string
): boolean => {
  const setting = state.settings?.find((s) => s.key === key);
  if (!setting) {
    return false;
  }
  const parsedValue = JSON.parse(setting?.jsonValue || '{}');
  return parsedValue?.IsEnabled;
};

export const getPhoneLinePreference = (
  state: SettingState
): PhoneLinePreference => {
  const setting = state.settings?.find(
    (s) => s.key === 'General_PhoneLinePreference'
  );
  const parsedValue = JSON.parse(setting?.jsonValue || '{}');

  const phoneLinePreference = Object.keys(parsedValue).find(
    (key) => parsedValue[key] === true
  ) as PhoneLinePreference;
  return phoneLinePreference;
};

export const getIsFetchingAiSetting: (state: SettingState) => boolean = (
  state
) => state?.isFetching;

export const getHasErrorAiSetting: (state: SettingState) => boolean = (state) =>
  state?.hasError;

export const getErrorMessageAiSetting: (state: SettingState) => string = (
  state
) => state?.errorMessage;
