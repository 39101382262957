import { ApiResult, apiUrl } from '../../../api';
import { DriveItem } from '../../../models/Drive';
import { MailFolder } from '../../../models/MailFolder';
import {
  DriveItemsToAttachmentRequest,
  DriveItemToAttachmentRequest,
  EmailSearchResultContact,
  EmailSearchResultProject,
  Message,
  MessageAttachment,
  MessageDriveItemMap,
  MessageNextLinkPayload,
  MessageUpdate,
  SaveAttachmentsToCloud,
} from '../../../models/Message';
import {
  DocumentPrefixProjectSettingPreview,
  EmailProjectSettingPreview,
} from '../../../models/Project';
import {
  CompanyId,
  ContactId,
  DriveItemId,
  GlobalProjectSettingId,
  GroupId,
  MailFolderId,
  MessageId,
  ProjectId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import { urltoFile } from '../../../util';
import { apiDocumentUploadUrl } from '../../documents/api';
import { SpecialMailFolders } from '../actions/types';
import equals from 'deep-equal';
import { AdvancedMailSearchDto } from '../../../models/MailSearch';
import { RcFile } from 'antd/lib/upload';
import fetchWithRetry from '../../../util/fetchWithRetry';
import * as imageConversion from 'image-conversion';

const messageControllerGenerator: (projectId: string) => string = (
  projectId
) => {
  return projectId === 'me' ? '/email/EmailMe' : `/email/Email/${projectId}`;
};

const mailFolderControllerGenerator: (projectId: string) => string = (
  projectId
) => {
  return projectId === 'me'
    ? '/email/MailFolder/Me'
    : `/email/MailFolder/${projectId}`;
};

export const apiFetchProjectCompanyMailSignature: (
  projectId: ProjectId,
  companyId: CompanyId,
  globalProjectSettingId: GlobalProjectSettingId,
  useProjectSignature: boolean,
  signal?: AbortSignal
) => Promise<ApiResult<string>> = async (
  projectId,
  companyId,
  globalProjectSettingId,
  useProjectSignature,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/signature?globalSignatureId=${globalProjectSettingId}&officeId=${companyId}
    ${projectId ? '&projectId=' + projectId : ''}
    ${
      useProjectSignature ? '&useProjectSignature=' + useProjectSignature : ''
    }`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiMessageAddCategories: (
  projectId: ProjectId,
  messageIds: MessageId[],
  categories: string[]
) => Promise<ApiResult<void>> = async (projectId, messageIds, categories) => {
  const payload = {
    messages: messageIds.map((messageId) => ({
      messageId,
      categories,
    })),
  };
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/categories`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    }
  );
  return { result };
};

export const apiMessageRemoveCategories: (
  projectId: ProjectId,
  messageIds: MessageId[],
  categories: string[]
) => Promise<ApiResult<void>> = async (projectId, messageIds, categories) => {
  const payload = {
    messages: messageIds.map((messageId) => ({
      messageId,
      categories,
    })),
  };
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/categories`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify(payload),
    }
  );
  return { result };
};

export const apiAttachmentUploadInlineImage: (
  file: File,
  projectId: ProjectId,
  messageId: MessageId,
  contentId: string
) => Promise<boolean> = async (file, projectId, messageId, contentId) => {
  let form = new FormData();
  form.append('file', file);
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/attachment/upload?isInline=true&contentId=${contentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: form,
      method: 'POST',
    }
  );
  if (!(result.status >= 200 && result.status < 300)) {
    return false;
  } else {
    return true;
  }
};

export const apiDownloadAttachment: (
  projectId: ProjectId,
  messageId: MessageId,
  messageAttachment: MessageAttachment
) => Promise<boolean> = async (projectId, messageId, messageAttachment) => {
  let blob: Blob = null;
  if (
    messageAttachment.contentBytes !== null &&
    messageAttachment.contentBytes !== undefined &&
    messageAttachment.contentBytes !== ''
  ) {
    blob = await urltoFile(
      `data:${messageAttachment.contentType};base64,${messageAttachment.contentBytes}`,
      messageAttachment.name,
      messageAttachment.contentType
    );
  } else {
    const result = await fetchWithRetry(
      `${apiUrl}${messageControllerGenerator(
        projectId
      )}/message/${messageId}/attachment/${messageAttachment.id}/download`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );
    if (!(result.status >= 200 && result.status < 300)) {
      return false;
    }
    blob = await result.blob();
  }
  if (blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    if (
      messageAttachment['@odata.type'] === '#microsoft.graph.itemAttachment' &&
      !messageAttachment.name.includes('.eml')
    ) {
      a.download = messageAttachment.name + '.eml';
    } else {
      a.download = messageAttachment.name;
    }

    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    return true;
  }
  return false;
};

export const apiDownloadAttachmentToBase64: (
  projectId: ProjectId,
  messageId: MessageId,
  messageAttachment: MessageAttachment,
  signal?: AbortSignal
) => Promise<string> = async (
  projectId,
  messageId,
  messageAttachment,
  signal
) => {
  let blob: Blob;
  let url: string = '';

  await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/attachment/${messageAttachment.id}/download`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  )
    .then(async (result) => {
      if (result.status >= 200 && result.status < 300) {
        let resultBlob = await result.blob();
        blob = resultBlob.slice(
          0,
          resultBlob.size,
          messageAttachment.contentType
        );
      }
    })
    .catch((err) => {
      console.error(err);
    });
  if (blob) {
    url = URL.createObjectURL(blob);
  }
  return url;
};

export const attachmentBlobToUrl: (
  contentBytes: string,
  contentType: string
) => string = (contentBytes, contentType) => {
  let blob: Blob;
  let url: string = '';

  blob = b64toBlob(contentBytes, contentType);
  if (blob) {
    url = URL.createObjectURL(blob);
  }
  return url;
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = Buffer.from(b64Data, 'base64'); //atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.at(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const apiDownloadAttachmentToCache: (
  projectId: ProjectId,
  messageId: MessageId,
  messageAttachment: MessageAttachment
) => Promise<Blob> = async (projectId, messageId, messageAttachment) => {
  let blob: Blob = null;

  if (messageAttachment.contentBytes) {
    blob = await urltoFile(
      `data:${messageAttachment.contentType};base64,${messageAttachment.contentBytes}`,
      messageAttachment.name,
      messageAttachment.contentType
    );
  } else {
    const result = await fetchWithRetry(
      `${apiUrl}${messageControllerGenerator(
        projectId
      )}/message/${messageId}/attachment/${messageAttachment.id}/download`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );

    if (!(result.status >= 200 && result.status < 300)) {
      return null;
    }
    blob = await result.blob();

    return blob;
  }

  return null;
};

export const apiAttachmentUploadUrl: (
  projectId: ProjectId,
  messageId: MessageId
) => string = (projectId, messageId) =>
  `${apiUrl}${messageControllerGenerator(
    projectId
  )}/message/${messageId}/attachment/upload`;

export const apiAttachmentUpload: (
  projectId: ProjectId,
  messageId: MessageId,
  file: RcFile,
  signal?: AbortSignal
) => Promise<ApiResult<MessageAttachment[]>> = async (
  projectId,
  messageId,
  file,
  signal
) => {
  const url = apiAttachmentUploadUrl(projectId, messageId);
  let formData = new FormData();
  formData.append('file', file);
  formData.append('filePath', file.webkitRelativePath);

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'POST',
    signal,
    body: formData,
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiDeleteAttachment: (
  projectId: ProjectId,
  messageId: MessageId,
  attachmentId: string
) => Promise<ApiResult<void>> = async (projectId, messageId, attachmentId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/attachment/${attachmentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiCreateMailFolder: (
  displayName: string,
  projectId: ProjectId,
  parentFolderId?: MailFolderId
) => Promise<ApiResult<void>> = async (
  displayName,
  projectId,
  parentFolderId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${mailFolderControllerGenerator(projectId)}/mailFolders${
      parentFolderId ? `/${parentFolderId}/childFolders` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ displayName }),
    }
  );
  return { result };
};

export const apiDeleteMailFolder: (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  isMe?: boolean
) => Promise<ApiResult<void>> = async (projectId, mailFolderId, isMe) => {
  const result = await fetchWithRetry(
    `${apiUrl}${mailFolderControllerGenerator(
      projectId
    )}/mailFolders/${mailFolderId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiSoftDeleteMessage: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<void>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/softdelete`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify([messageId]),
    }
  );
  return { result };
};

export const apiSoftDeleteMessages: (
  projectId: ProjectId,
  messageIds: MessageId[]
) => Promise<ApiResult<void>> = async (projectId, messageIds) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/softdelete`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(messageIds),
    }
  );
  return { result };
};

export const apiDeleteMessage: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<void>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/${messageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiMovePersonalMessage: (
  messageId: MessageId,
  targetProjectId: ProjectId,
  targetMailFolderId: MailFolderId
) => Promise<ApiResult<void>> = async (
  messageId,
  targetProjectId,
  targetMailFolderId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${targetProjectId}/mailFolders/${targetMailFolderId}/move?personalMessage=true`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ messageId }),
    }
  );
  return { result };
};

export const apiMoveMailFolder: (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  destinationId: MailFolderId
) => Promise<ApiResult<void>> = async (
  projectId,
  mailFolderId,
  destinationId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${mailFolderControllerGenerator(
      projectId
    )}/mailFolders/${mailFolderId}/move`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ destinationId }),
    }
  );
  return { result };
};

export const apiFetchAttachments: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<MessageAttachment[]>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/attachment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDraftMessages: (
  projectId: ProjectId,
  conversationId: string
) => Promise<ApiResult<Message[]>> = async (projectId, conversationId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/draft/${conversationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateMessageReply: (
  projectId: ProjectId,
  messageId: string,
  type: 'Reply' | 'ReplyAll' | 'Forward'
) => Promise<ApiResult<Message>> = async (projectId, messageId, type) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/create${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export interface CreateMessageDraftOptions {
  body?: any;
  attachFolderDriveItemId?: DriveItemId;
}

export const apiCreateMessageDraft: (
  projectId: ProjectId,
  options?: CreateMessageDraftOptions
) => Promise<ApiResult<Message>> = async (projectId, options) => {
  const body = options?.body ?? {
    contentType: 1,
    content: '<p></p><p></p><p></p>',
  };
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        body,
        //attachFolderDriveItemId: options?.attachFolderDriveItemId,
        //TODO: uncomment once API is available
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiFetchMessage: (
  projectId: ProjectId,
  draftMessageId: MessageId,
  signal?: AbortSignal
) => Promise<ApiResult<Message>> = async (
  projectId,
  draftMessageId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${draftMessageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateMessageDraft: (
  projectId: ProjectId,
  messageId: string,
  message: MessageUpdate,
  retryWithErrorResponse?: boolean,
  signal?: AbortSignal
) => Promise<ApiResult<Message>> = async (
  projectId,
  messageId,
  message,
  retryWithErrorResponse = false,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(projectId)}/message/${messageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(message),
      signal,
    },
    retryWithErrorResponse
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSendMessage: (
  projectId: ProjectId,
  messageId: string,
  retryWithErrorResponse?: boolean
) => Promise<ApiResult<void>> = async (
  projectId,
  messageId,
  retryWithErrorResponse = false
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/send`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
    retryWithErrorResponse
  );
  return {
    result,
  };
};

export const apiSaveAndSendMessage: (
  projectId: ProjectId,
  messageId: string,
  message: MessageUpdate,
  retryWithErrorResponse?: boolean
) => Promise<ApiResult<void>> = async (
  projectId,
  messageId,
  message,
  retryWithErrorResponse = false
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/saveAndSend`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(message),
    },
    retryWithErrorResponse
  );
  return {
    result,
  };
};

export const apiSaveAttachmentsToCloud: (
  projectId: ProjectId,
  data: SaveAttachmentsToCloud
) => Promise<ApiResult<void>> = async (projectId, data) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/saveAttachmentToCloud`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      method: 'POST',
    }
  );
  return {
    result,
  };
};

export const apiUploadFile: (
  file: RcFile,
  projectId: ProjectId,
  groupId: GroupId,
  driveItemId: DriveItemId,
  signal?: AbortSignal
) => Promise<ApiResult<File>> = async (
  file,
  projectId,
  groupId,
  driveItemId,
  signal
) => {
  const url = apiDocumentUploadUrl(projectId, groupId, driveItemId);
  let formData = new FormData();
  formData.append('file', file);
  formData.append('filePath', file.webkitRelativePath);

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'POST',
    signal,
    body: formData,
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUploadAttachments: (
  file: File,
  projectId: ProjectId,
  groupId: GroupId,
  driveItemId: DriveItemId,
  signal?: AbortSignal
) => Promise<ApiResult<MessageAttachment[]>> = async (
  file,
  projectId,
  groupId,
  driveItemId,
  signal
) => {
  const url = apiDocumentUploadUrl(projectId, groupId, driveItemId);
  let formData = new FormData();
  formData.append('file', file);
  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'POST',
    signal,
    body: formData,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiFetchMailFolders: (
  projectId: ProjectId
) => Promise<ApiResult<MailFolder[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${mailFolderControllerGenerator(projectId)}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiSearchEmailAddress: (
  searchString: string
) => Promise<
  ApiResult<(EmailSearchResultContact | EmailSearchResultProject)[]>
> = async (searchString) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/email/searchEmailAdress?searchString=${searchString}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMessagesMe: (
  mailFolderId: MailFolderId,
  nextLink?: string
) => Promise<ApiResult<MessageNextLinkPayload>> = async (
  mailFolderId,
  nextLink
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/EmailMe/message/folder/${mailFolderId}/delta${
      nextLink ? `?nextLink=${nextLink}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMessagesProject: (
  projectId: string,
  mailFolderId: string,
  nextLink?: string,
  cachingEnabled?: boolean,
  sharedMailboxFolderId?: string
) => Promise<ApiResult<MessageNextLinkPayload>> = async (
  projectId,
  mailFolderId,
  nextLink,
  cachingEnabled,
  sharedMailboxFolderId
) => {
  const queryParameterValues = [
    cachingEnabled && mailFolderId === 'inbox',
    sharedMailboxFolderId && cachingEnabled && mailFolderId === 'inbox',
    !!nextLink,
  ];
  const queryParameters = [
    `cachingEnabled=${cachingEnabled}`,
    `sharedMailboxFolderId=${sharedMailboxFolderId}`,
    `nextLink=${nextLink}`,
  ]
    .filter((item, index) => queryParameterValues[index])
    .join('&');

  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${
      cachingEnabled && mailFolderId === 'inbox'
        ? `project/${projectId}/mailFolder/${mailFolderId}/message`
        : `${projectId}/message/folder/${mailFolderId}/delta`
    }${queryParameters.length > 0 ? `?${queryParameters}` : ''}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMailSearchMe: (
  searchTerm: AdvancedMailSearchDto | string,
  mailFolderId?: MailFolderId,
  nextLink?: string
) => Promise<ApiResult<MessageNextLinkPayload>> = async (
  searchTerm,
  mailFolderId,
  nextLink
) => {
  const isString: boolean = typeof searchTerm === 'string';
  const url: string = isString
    ? `${apiUrl}/email/EmailMe/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message?search=${searchTerm}${nextLink ? `&nextLink=${nextLink}` : ''}`
    : `${apiUrl}/email/EmailMe/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message/search${
        equals(searchTerm, {})
          ? ''
          : `?${Object.keys(searchTerm)
              .filter(
                (key) =>
                  !(searchTerm[key] === undefined || searchTerm[key] === null)
              )
              .map(
                (key) =>
                  `${key}=${
                    Array.isArray(searchTerm[key])
                      ? searchTerm[key].join(',')
                      : searchTerm[key]
                  }`
              )
              .join('&')}`
      }${
        nextLink
          ? `${equals(searchTerm, {}) ? '?' : '&'}nextLink=${nextLink}`
          : ''
      }`;
  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMailSearchProjects: (
  searchTerm: AdvancedMailSearchDto | string,
  projectId: ProjectId,
  mailFolderId?: MailFolderId,
  nextLink?: string
) => Promise<ApiResult<MessageNextLinkPayload>> = async (
  searchTerm,
  projectId,
  mailFolderId,
  nextLink
) => {
  const isString: boolean = typeof searchTerm === 'string';
  const url: string = isString
    ? `${apiUrl}/email/Email/project/${projectId}/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message?search=${searchTerm}${nextLink ? `&nextLink=${nextLink}` : ''}`
    : `${apiUrl}/email/Email/project/${projectId}/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message/search${
        equals(searchTerm, {})
          ? ''
          : `?${Object.keys(searchTerm)
              .filter(
                (key) =>
                  !(searchTerm[key] === undefined || searchTerm[key] === null)
              )
              .map(
                (key) =>
                  `${key}=${
                    Array.isArray(searchTerm[key])
                      ? searchTerm[key].join(',')
                      : searchTerm[key]
                  }`
              )
              .join('&')}`
      }${
        nextLink
          ? `${equals(searchTerm, {}) ? '?' : '&'}nextLink=${nextLink}`
          : ''
      }`;

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAddDriveItemToAttachment: (
  groupId: GroupId,
  driveItemId: DriveItemId,
  messageId: MessageId,
  projectId: ProjectId
) => Promise<ApiResult<MessageAttachment>> = async (
  groupId,
  driveItemId,
  messageId,
  projectId
) => {
  const payload: DriveItemToAttachmentRequest = {
    groupId,
    driveItemId,
    messageId,
  };
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/addDriveItemToAttachment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAddDriveItemsToAttachment: (
  groupId: GroupId,
  driveItemIds: DriveItemId[],
  messageId: MessageId,
  projectId: ProjectId
) => Promise<ApiResult<MessageAttachment>> = async (
  groupId,
  driveItemIds,
  messageId,
  projectId
) => {
  const payload: DriveItemsToAttachmentRequest = {
    groupId,
    driveItemIds,
  };

  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/addDriveItemsToAttachment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAddEmailAsEmailAttachment: (
  projectId: ProjectId,
  attachmentMessageId: MessageId,
  targetMessage: MessageId
) => Promise<ApiResult<MessageAttachment>> = async (
  projectId,
  targetMessage,
  attachmentMessageId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${attachmentMessageId}/addMailAsAttachment/${targetMessage}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(''),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiFetchSpecialMailFolders: (
  projectId: ProjectId
) => Promise<ApiResult<SpecialMailFolders>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${mailFolderControllerGenerator(projectId)}/specialMailFolder`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAddEmlToDriveFolder: (
  projectId: ProjectId,
  messageId: MessageId,
  parentDriveItemId: DriveItemId,
  destinationGroupId: GroupId
) => Promise<ApiResult<DriveItem>> = async (
  projectId,
  messageId,
  parentDriveItemId,
  destinationGroupId
) => {
  const payload = {
    messageId: messageId,
    parentDriveItemId: parentDriveItemId,
    destinationGroupId: destinationGroupId,
  };
  let url = `${apiUrl}/email/email/${projectId}/createEml`;
  if (projectId === undefined || projectId === 'me') {
    url = `${apiUrl}/email/emailMe/createEml`;
  }

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchEmailProjectSettingPreview: (
  projectId: ProjectId,
  globalProjectSettingTypes: string[]
) => Promise<ApiResult<EmailProjectSettingPreview>> = async (
  projectId,
  globalProjectSettingTypes
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${projectId}/EmailProjectSettingPreview?globalProjectSettingTypes=${globalProjectSettingTypes.join()}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentProjectSettingPreview: (
  projectId: ProjectId,
  globalProjectSettingTypes: string[]
) => Promise<ApiResult<DocumentPrefixProjectSettingPreview>> = async (
  projectId,
  globalProjectSettingTypes
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/preview?globalProjectSettingTypes=${globalProjectSettingTypes.join()}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInlineImages: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<Message>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/InlineImages`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAddressSuggestions: (
  projectId: string,
  returnedEmailHitCap?: number
) => Promise<
  ApiResult<(EmailSearchResultContact | EmailSearchResultProject)[]>
> = async (projectId, returnedEmailHitCap) => {
  const result = await fetchWithRetry(
    `${apiUrl}${
      projectId === 'me'
        ? '/email/EmailMe'
        : `/email/Email/project/${projectId}`
    }/addressSuggestions${
      returnedEmailHitCap ? `?returnedEmailHitCap=${returnedEmailHitCap}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteEmailSuggestion: (
  projectId: string,
  email?: string
) => Promise<ApiResult<void>> = async (projectId, email) => {
  const result = await fetchWithRetry(
    `${apiUrl}${
      projectId === 'me'
        ? '/email/EmailMe'
        : `/email/Email/project/${projectId}`
    }/addressSuggestions/${email}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );

  return {
    result,
  };
};
export const apiDecodeMime: (
  projectId: ProjectId,
  messageId: MessageId,
  simpleWay?: boolean,
  signal?: AbortSignal
) => Promise<ApiResult<Message>> = async (
  projectId,
  messageId,
  simpleWay,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/decodeMime${
      simpleWay ? '?simpleWay=true' : '?simpleWay=true'
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchParsedSmimeAttachments: (
  projectId: ProjectId,
  messageId: MessageId,
  attachmentId: string,
  signal?: AbortSignal
) => Promise<ApiResult<MessageAttachment[]>> = async (
  projectId,
  messageId,
  attachmentId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/parseSmimeAttachment/${attachmentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      signal,
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchEventMessage: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<Message>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/eventMessage/${messageId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGenerateNextLink: (
  projectId: ProjectId,
  skip: number
) => Promise<ApiResult<string>> = async (projectId, skip) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${projectId}/message/folder/inbox/nextLink?skip=${skip}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUploadInlineImage = async (
  projectId: ProjectId,
  messageId: MessageId,
  file: File,
  contentId: string
) => {
  let _file;
  if (file.size > 3000) {
    let res = await imageConversion.compressAccurately(file, 700);
    _file = new File([res], file.name);
  } else {
    _file = file;
  }

  // parse
  await apiAttachmentUploadInlineImage(_file, projectId, messageId, contentId);
};

export const apiAssignMessage: (
  projectId: ProjectId,
  messageId: MessageId,
  contactId: ContactId
) => Promise<ApiResult<Message>> = async (projectId, messageId, contactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/assignment/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAssignMessages: (
  projectId: ProjectId,
  messageIds: MessageId[],
  contactId: ContactId
) => Promise<ApiResult<void>> = async (projectId, messageIds, contactId) => {
  const url = `${apiUrl}${messageControllerGenerator(
    projectId
  )}/messages/assignment${contactId ? `?contactId=${contactId}` : ''}`;
  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(messageIds),
  });
  return { result };
};

export const apiUnassignMessage: (
  projectId: ProjectId,
  messageId: MessageId
) => Promise<ApiResult<void>> = async (projectId, messageId) => {
  const result = await fetchWithRetry(
    `${apiUrl}${messageControllerGenerator(
      projectId
    )}/message/${messageId}/assignment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiFetchSavedAttachmentMetadata: (
  messageIds: MessageId[]
) => Promise<ApiResult<MessageDriveItemMap[]>> = async (messageIds = []) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/savedAttachmentMetadata?messageIds=${messageIds.join(
      ','
    )}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return { result, data };
};

export const apiCopyMessageToProject: (
  messageId: MessageId,
  projectId: ProjectId,
  targetProjectId: ProjectId,
  destinationMailFolderId: MailFolderId,
  deleteMail?: boolean,
  copyAttachments?: boolean
) => Promise<ApiResult<void>> = async (
  messageId,
  projectId,
  targetProjectId,
  destinationMailFolderId,
  deleteMail,
  copyAttachments
) => {
  const payload = {
    destinationMailFolder: destinationMailFolderId,
    deleteMail: deleteMail,
    copyAttachments: copyAttachments,
  };

  const endpoint = `/email/${
    projectId === 'me' ? 'EmailMe' : `Email/${projectId}`
  }/message/${messageId}/copyToProject/${targetProjectId}`;

  const result = await fetchWithRetry(`${apiUrl}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return { result };
};
