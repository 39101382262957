import {
  FlagMessagePayload,
  Message,
  MessageAttachment,
  MessageCategorization,
} from '../../../../models/Message';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import {
  addProjectMessageToCache,
  categorizedMessageProject,
  commitMessageWithMimeContentProject,
  copyMessageToProjectProject,
  deleteLocalMessageProject,
  deleteMessagesProject,
  fetchMessageProject,
  fetchMessagesProject,
  fetchMessageWithInlineImageProject,
  flagMessageProject,
  markAsReadProject,
  moveMessageProject,
  putMessageProjectAttachmentToCache,
  softdeleteMessagesProject,
  updateMessageProject,
  updateMessagesProject,
  wsRevertMessageDeleteProject,
  wsRevertMessageMoveProject,
} from '../projects/messages';
import {
  addMeMessageToCache,
  categorizedMessageMe,
  commitMessageWithMimeContentMe,
  copyMessageToProjectMe,
  deleteLocalMessageMe,
  deleteMessagesMe,
  fetchMessageMe,
  fetchMessagesMe,
  fetchMessageWithInlineImageMe,
  flagMessageMe,
  markAsReadMe,
  moveMessageMe,
  putMessageMeAttachmentToCache,
  softdeleteMessagesMe,
  updateMessageMe,
  updateMessagesMe,
  wsRevertMessageDeleteMe,
  wsRevertMessageMoveMe,
} from '../me/messagesMe';
import { PRIO } from '../../../../constants';

export const fetchMessages = (
  projectId: string,
  mailFolderId: string,
  movingMessageIds: MessageId[],
  messageIdsToDelete: MessageId[],
  resync: boolean = true,
  nextLink?: string,
  sharedMailboxFolderId?: string
) => {
  if (projectId === 'me') {
    return fetchMessagesMe(mailFolderId, movingMessageIds, nextLink);
  }
  return fetchMessagesProject(
    projectId,
    mailFolderId,
    sharedMailboxFolderId,
    movingMessageIds,
    messageIdsToDelete,
    resync,
    nextLink
  );
};

export const fetchMessage = (projectId: ProjectId, messageId: MessageId) => {
  if (projectId === 'me') {
    return fetchMessageMe(messageId);
  }
  return fetchMessageProject(projectId, messageId);
};

export const DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME =
  PRIO + 'DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME';
export const DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT =
  PRIO + 'DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT';

export const deleteMessagesOfMailbox = (projectId: ProjectId) => ({
  type:
    projectId === 'me'
      ? DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME
      : DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT,
  ...(projectId !== 'me' ? { projectId } : {}),
});

export const deleteMessages = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messageIds: MessageId[]
) => {
  if (projectId === 'me') {
    return deleteMessagesMe(mailFolderId, messageIds);
  }
  return deleteMessagesProject(projectId, mailFolderId, messageIds);
};

export const softdeleteMessages = (
  projectId: ProjectId,
  messageIds: MessageId[],
  rollbackMessages: Message[],
  mailFolderId?: MailFolderId
) => {
  if (projectId === 'me') {
    return softdeleteMessagesMe(messageIds, rollbackMessages, mailFolderId);
  }
  return softdeleteMessagesProject(
    projectId,
    messageIds,
    rollbackMessages,
    mailFolderId
  );
};

export const updateMessage = (
  projectId: ProjectId,
  messageId: MessageId,
  messageUpdate: Partial<Message>
) => {
  if (projectId === 'me') {
    return updateMessageMe(messageId, messageUpdate);
  }
  return updateMessageProject(projectId, messageId, messageUpdate);
};

export const updateMessages = (
  projectId: ProjectId,
  messageIds: MessageId[],
  messageUpdates: { messageId: MessageId; messageUpdate: Partial<Message> }[]
) => {
  if (projectId === 'me') {
    return updateMessagesMe(messageIds, messageUpdates);
  }
  return updateMessagesProject(projectId, messageIds, messageUpdates);
};

export const categorizedMessage = (
  projectId: ProjectId,
  messages: MessageCategorization,
  isDelete: boolean
) => {
  if (projectId === 'me') {
    return categorizedMessageMe(messages, isDelete);
  }
  return categorizedMessageProject(messages, isDelete);
};

export const markAsRead = (
  projectId: ProjectId,
  messageIds: MessageId[],
  isRead: boolean,
  mailFolderId: MailFolderId,
  changedUnreadItemCount: number
) => {
  if (projectId === 'me') {
    return markAsReadMe(
      messageIds,
      isRead,
      mailFolderId,
      changedUnreadItemCount
    );
  }
  return markAsReadProject(
    projectId,
    messageIds,
    isRead,
    mailFolderId,
    changedUnreadItemCount
  );
};

export const flagMessage = (
  projectId: ProjectId,
  payload: FlagMessagePayload,
  originalPayload: FlagMessagePayload
) => {
  if (projectId === 'me') {
    return flagMessageMe(payload, originalPayload);
  }
  return flagMessageProject(projectId, payload, originalPayload);
};

export const deleteLocalMessage = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return deleteLocalMessageMe(mailFolderId, messageId);
  }
  return deleteLocalMessageProject(projectId, mailFolderId, messageId);
};

export const fetchMessageWithInlineImage = (
  projectId: string,
  messageId: string
) => {
  if (projectId === 'me') {
    return fetchMessageWithInlineImageMe(messageId);
  }
  return fetchMessageWithInlineImageProject(projectId, messageId);
};

export const commitMessageWithMimeContent = (
  projectId: string,
  messageId: string,
  message: Message
) => {
  if (projectId === 'me') {
    return commitMessageWithMimeContentMe(messageId, message);
  }
  return commitMessageWithMimeContentProject(messageId, message);
};

export const putMessageAttachmentToCache = (
  projectId: string,
  messageId: string,
  messageAttachment: MessageAttachment[]
) => {
  if (projectId === 'me') {
    return putMessageMeAttachmentToCache(messageId, messageAttachment);
  }
  return putMessageProjectAttachmentToCache(messageId, messageAttachment);
};

export const DELETE_LOCAL_MESSAGES = PRIO + 'DELETE_LOCAL_MESSAGES';

export const deleteLocalMessages = () => ({
  type: DELETE_LOCAL_MESSAGES,
});

export const copyMessageToProject = (
  messages: Message[],
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  destinationMailFolderId: MailFolderId,
  targetProjectId: ProjectId,
  deleteMail: boolean,
  checkForDuplicates: boolean,
  hideDuplicateMessages: boolean
) => {
  if (projectId === 'me') {
    return copyMessageToProjectMe(
      messages,
      mailFolderId,
      destinationMailFolderId,
      targetProjectId,
      deleteMail,
      checkForDuplicates,
      hideDuplicateMessages
    );
  }
  return copyMessageToProjectProject(
    messages,
    projectId,
    mailFolderId,
    destinationMailFolderId,
    targetProjectId,
    deleteMail,
    checkForDuplicates,
    hideDuplicateMessages
  );
};

export const moveMessage = (
  projectId: ProjectId,
  messages: Message[],
  destinationId: MailFolderId,
  originId: MailFolderId,
  inboxFolderId: MailFolderId
) => {
  if (projectId === 'me') {
    return moveMessageMe(messages, destinationId, originId, inboxFolderId);
  }
  return moveMessageProject(
    projectId,
    messages,
    destinationId,
    originId,
    inboxFolderId
  );
};

export const wsRevertMessageMove = (
  projectId: ProjectId,
  destinationId: MailFolderId,
  messageId: MessageId,
  message: Message,
  inboxFolderId: MailFolderId
) => {
  if (projectId === 'me') {
    return wsRevertMessageMoveMe(
      destinationId,
      messageId,
      message,
      inboxFolderId
    );
  }
  return wsRevertMessageMoveProject(
    projectId,
    destinationId,
    messageId,
    message,
    inboxFolderId
  );
};

export const wsRevertDeleteMessage = (
  projectId: ProjectId,
  sourceId: MailFolderId,
  messageId: MessageId,
  message: Message
) => {
  if (projectId === 'me') {
    return wsRevertMessageDeleteMe(sourceId, messageId, message);
  }
  return wsRevertMessageDeleteProject(projectId, sourceId, messageId, message);
};

export const addMessageToCache = (projectId: ProjectId, message: Message) => {
  if (projectId === 'me') {
    return addMeMessageToCache(message);
  }
  return addProjectMessageToCache(message);
};
