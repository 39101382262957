import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { EmergencyContact } from '../../../../models/Employee';
import { EmployeeId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { apiFetchEmergencyContacts } from '../../../profile/api';
import { useParams } from 'react-router-dom';
import { PrioSpinner, Tooltip } from '@prio365/prio365-react-library';
import { getCallingHref } from '../../../contacts/utils';
import { useSelector } from 'react-redux';
import { getPhoneLinePreference } from '../../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    maxHeight: '100%',
    fontSize: theme.font.fontSize.small,
    paddingTop: theme.spacing.regular,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing.regular,
    overflowY: 'auto',
  },
  spinnerRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  contactCard: {
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing.regular}px ${theme.spacing.large}px`,
    border: `1px solid ${theme.colors.application.typography.muted}50`,
    borderRadius: theme.borderRadius.regular,
  },
  icon: {
    width: 16,
    height: 16,
  },
  nameValue: {
    fontSize: 16,
  },
  relationshipValue: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
    marginBottom: theme.spacing.regular,
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
    paddingRight: theme.spacing.small,
  },
  phoneValue: {
    marginLeft: theme.spacing.small,
  },
  callIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    animation: '$shake 3s ease-in-out infinite', // Apply the shake animation
  },
  noItems: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
  '@keyframes shake': {
    '0%': { transform: 'rotate(0deg)' }, // Start with a 0 degree position
    '5%': { transform: 'rotate(15deg)' }, // Rotate to 15 degrees
    '10%': { transform: 'rotate(-15deg)' }, // Rotate back to 0 degrees
    '15%': { transform: 'rotate(15deg)' }, // Rotate to -15 degrees
    '20%': { transform: 'rotate(-15deg)' }, // Rotate back to 15 degrees
    '25%': { transform: 'rotate(15deg)' }, // Start with a 0 degree position
    '30%': { transform: 'rotate(-15deg)' }, // Rotate to 15 degrees
    '35%': { transform: 'rotate(15deg)' }, // Rotate back to 0 degrees
    '40%': { transform: 'rotate(-15deg)' }, // Rotate to -15 degrees
    '45%': { transform: 'rotate(15deg)' }, // Rotate back to 15 degrees
    '50%': { transform: 'rotate(-15deg)' }, // Rotate to 15 degrees
    '55%': { transform: 'rotate(15deg)' }, // Rotate back to 0 degrees
    '60%': { transform: 'rotate(-15deg)' }, // Rotate to -15 degrees
    '65%': { transform: 'rotate(0deg)' }, // Rotate back to 15 degrees
  },
}));

interface PersonnelFileEmergencyContactsProps {
  employeeId: EmployeeId;
}

export const PersonnelFileEmergencyContacts: React.FC<
  PersonnelFileEmergencyContactsProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { employeeId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { officeId } = useParams();

  const { data: _emergencyContacts, isLoading: isEmergencyContactsLoading } =
    useQuery({
      queryKey: ['employee', 'apiFetchEmergencyContacts', employeeId, officeId],
      queryFn: () => apiFetchEmergencyContacts(employeeId, officeId),
    });

  const emergencyContacts = useMemo(
    () => _emergencyContacts?.data || [],
    [_emergencyContacts]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Render
  const renderContacts = () => {
    return emergencyContacts.map((contact) => (
      <ContactCard key={contact.emergencyContactId} contact={contact} />
    ));
  };
  //#endregion

  if (isEmergencyContactsLoading) {
    return (
      <div className={classes.spinnerRow}>
        <PrioSpinner size="small" />
      </div>
    );
  }

  if (emergencyContacts.length === 0) {
    return (
      <div className={classes.noItems}>
        {t('hr:emergencyContacts.noContacts')}
      </div>
    );
  }
  return <div className={classes.root}>{renderContacts()}</div>;
};

export default PersonnelFileEmergencyContacts;

interface ContactCardProps {
  className?: string;
  contact?: EmergencyContact;
}

const ContactCard: React.FC<ContactCardProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    contact = {
      name: '',
      relationship: '',
      phone: '',
      mobile: '',
    },
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isPhoneHovered, setIsPhoneHovered] = useState<boolean>(false);
  const [isMobileHovered, setIsMobileHovered] = useState<boolean>(false);

  const phoneLinePreference = useSelector(getPhoneLinePreference);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const callContact = (phone: string) => {
    const a = document.createElement('a');

    a.href = getCallingHref(phoneLinePreference, undefined, undefined, phone);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Render
  const renderRow = (
    type: 'phone' | 'mobile',
    value: string,
    setHoverState: (value: boolean) => void,
    hoverState: boolean
  ) => {
    return (
      <Tooltip overlay={t('hr:personnelFile.actions.call')} placement="left">
        <div
          className={classes.phoneRow}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
          onClick={() => {
            callContact(value);
          }}
        >
          <FontAwesomeIcon
            icon={[hoverState ? 'fas' : 'fal', type]}
            color={theme.colors.base.primary.default}
            className={hoverState ? classes.callIcon : classes.icon}
            href={getCallingHref(
              phoneLinePreference,
              undefined,
              undefined,
              value
            )}
          />
          <div className={classes.phoneValue}>{value}</div>
        </div>
      </Tooltip>
    );
  };
  //#endregion

  return (
    <div className={classNames(classes.contactCard, className)}>
      <div className={classes.nameValue}>{contact.name}</div>
      <div className={classes.relationshipValue}>{contact.relationship}</div>

      {renderRow('phone', contact.phone, setIsPhoneHovered, isPhoneHovered)}
      {renderRow('mobile', contact.mobile, setIsMobileHovered, isMobileHovered)}
    </div>
  );
};
