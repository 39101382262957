import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Select } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPhoneLinePreference,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { PhoneLinePreference } from '../../../models/Types';
import { useQuery } from '@tanstack/react-query';
import { SettingKey, UpdateSetting } from '../../../models/Settings';
import { updateUserSetting } from '../../settings/actions/setting';
import { apiFetchSettings } from '../../settings/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    color: theme.colors.application.typography.default,
    padding: theme.spacing.extraLarge,
    background: theme.colors.application.background.light,
    height: '100%',
  },
  whiteBox: {
    background: theme.colors.base.white.default,
    padding: theme.spacing.large,
  },
  wrapper: {
    background: theme.colors.application.background.light,
    padding: theme.spacing.large,
  },

  h1: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
  },
  h2: {
    fontSize: theme.font.fontSize.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
  label: {
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.application.typography.muted,
  },
}));

interface PersonalSettingsPageProps {
  className?: string;
}

export const PersonalSettingsPage: React.FC<PersonalSettingsPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);
  const phoneLinePreference = useSelector(getPhoneLinePreference);

  const officePhoneLinePreferenceSettingsKey = useMemo(
    () =>
      [
        {
          scopeId: userMe.myOfficeId,
          eSettingScope: 'office',
          keys: ['General_PhoneLinePreference'],
        },
      ] as SettingKey[],
    [userMe.myOfficeId]
  );

  const { data: officePhoneLinePreferenceApiResult } = useQuery({
    queryKey: ['officePhoneLinePreference'],
    queryFn: () => apiFetchSettings(officePhoneLinePreferenceSettingsKey),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
  const officePhoneLinePreferenceResult = JSON.parse(
    officePhoneLinePreferenceApiResult?.data?.[0].jsonValue ?? '{}'
  );

  const phoneLinePreferenceOptionKeys = Object.keys(
    officePhoneLinePreferenceResult
  );

  const phoneLinePreferenceByOffice = Object.entries(
    officePhoneLinePreferenceResult
  ).find(([_, value]) => value === true)?.[0];

  const phoneLinePreferenceOptions = phoneLinePreferenceOptionKeys.map(
    (item) => ({
      value: item,
      label: t(`profile:personalSettings.phoneLinePreference.${item}`, {
        isDefaultByOffice:
          item === phoneLinePreferenceByOffice
            ? ` ${t('profile:personalSettings.phoneLinePreference.default')}`
            : '',
      }),
    })
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handlePhoneLinePreferenceChange = useCallback(
    async (value: PhoneLinePreference) => {
      const jsonValue =
        value === 'PreferTeams'
          ? {
              PreferTeams: true,
              PreferPhone: false,
            }
          : {
              PreferTeams: false,
              PreferPhone: true,
            };
      const updatedSettings = {
        jsonValue: JSON.stringify(jsonValue),
        parentSettingId: '',
        relationId: '',
        isDefault: true,
        description: '',
      } as UpdateSetting;

      dispatch(
        updateUserSetting(
          updatedSettings,
          userMe.id,
          'General_PhoneLinePreference'
        )
      );
    },
    [userMe.id, dispatch]
  );

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row className={classes.whiteBox}>
        <Flex.Column width={'100%'} childrenGap={theme.spacing.regular}>
          <div className={classes.h1}>
            {t('profile:personalSettings.header')}
          </div>
          <Flex.Row>
            <Flex.Column className={classes.wrapper} width={'100%'}>
              <div className={classes.h2}>
                {t('profile:personalSettings.general.header')}
              </div>
              <Flex.Row childrenGap={theme.spacing.regular}>
                <Flex.Column flex={1}>
                  <div className={classes.label}>
                    {t('profile:personalSettings.general.phoneLinePreference')}
                  </div>
                  <Select
                    showSearch={false}
                    value={phoneLinePreference}
                    options={phoneLinePreferenceOptions}
                    onChange={handlePhoneLinePreferenceChange}
                  />
                </Flex.Column>
                <Flex.Column flex={1}></Flex.Column>
              </Flex.Row>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </Flex.Column>
  );
};

export default PersonalSettingsPage;
