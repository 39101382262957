import React, { useMemo, useRef, useState } from 'react';
import { Message } from '../../../../models/Message';
import { html2text } from '../../../../util';
import { MessageId, ProjectId } from '../../../../models/Types';
import WidgetMailListItem from './WidgetMailListItem';
import { CreateTaskRequest } from '../../../../models/Task';
import TasksDrawer from '../../../tasks/components/TasksDrawer';
import VirtualList from '../../../../components/VirtualList';
import { useDispatch } from 'react-redux';
import { MailFolder } from '../../../../models/MailFolder';
import { MailSettings } from '../../../../models/UserSettings/MailSettings';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import { addMailListSeparators } from '../../util';
import moment from 'moment';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { fetchMessagesSagaAction } from '../../actions/sagas';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
    paddingRight: '1px',
    overflow: 'hidden',
  },
  separator: {
    borderBottom: theme.old.borders.content,
    paddingLeft: 4 + theme.old.components.mailListItem.spacing,
    fontSize: theme.old.components.mailListItem.fontSize,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    fontWeight: 500,
  },
}));

interface WidgetMailListProps {
  className?: string;
  items?: Message[];
  projectId: ProjectId;
  checkForDuplicates?: boolean;
  hideDuplicateMessages?: boolean;
  moveToFolder: (
    message: Message,
    selectedMessages: Message[],
    projectId: ProjectId
  ) => void;
  settings?: MailSettings;
  endDrop: (messageId: MessageId) => void;
  mailFolder?: MailFolder;
}

export const WidgetMailList: React.FC<WidgetMailListProps> = React.memo(
  (props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      items,
      projectId,
      settings,
      moveToFolder,
      endDrop,
      mailFolder,
      checkForDuplicates,
      hideDuplicateMessages,
    } = props;

    const classes = useStyles();
    const theme = useTheme<PrioTheme>();
    const dispatch = useDispatch();
    const ref = useRef(null);
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const rowHeight = useMemo(() => {
      if (ref.current) {
        ref.current.recomputeRowHeights();
        ref?.current?.forceUpdate();
      }
      return settings.mailListSpacing === 'full'
        ? 81
        : settings.mailListSpacing === 'middle'
        ? 73
        : 63;
    }, [settings, ref]);
    const [task, setTask] = useState<CreateTaskRequest>();
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const setRef = (element: any) => {
      ref.current = element;
    };

    const handleCreateTask = (message: Message) => {
      setTask({
        title: message.subject,
        description:
          message.body.contentType === 'html' ||
          message.body.contentType === 'Html' ||
          message.body.contentType === 1
            ? html2text(message.body.content)
            : message.body.content,
        projectId,
      });
    };
    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    return (
      <>
        <div className={classNames(classes.root, className)}>
          <VirtualList<Message>
            registerList={setRef}
            items={items}
            rowHeight={({ index }) => {
              const message = items[index];
              const previousMessage = index === 0 ? null : items[index - 1];
              if (
                addMailListSeparators(
                  moment(previousMessage?.receivedDateTime),
                  moment(message?.receivedDateTime)
                )
              ) {
                return (
                  rowHeight +
                  theme.old.spacing.unit(2) +
                  theme.old.components.mailListItem.spacing
                );
              }
              return rowHeight;
            }}
            isRowLoaded={({ index }) => {
              return index < items.length;
            }}
            loadMoreRows={async ({ startIndex, stopIndex }) => {
              dispatch(fetchMessagesSagaAction('me', 'inbox', false, true));
            }}
            totalItemCount={mailFolder?.totalItemCount}
            threshold={10}
            overscanRowCount={20}
            rowRenderer={({ index, key, style }) => {
              const message = items[index];
              const previousMessage = index === 0 ? null : items[index - 1];

              const separator = addMailListSeparators(
                !!previousMessage
                  ? moment(previousMessage?.receivedDateTime)
                  : undefined,
                moment(message?.receivedDateTime)
              );
              return (
                <>
                  {separator && (
                    <div
                      style={{
                        ...style,
                        height:
                          theme.old.spacing.unit(2) +
                          theme.old.components.mailListItem.spacing,
                      }}
                      className={classes.separator}
                    >
                      {separator}
                    </div>
                  )}
                  <WidgetMailListItem
                    key={message.id}
                    message={message}
                    projectId={projectId}
                    checkForDuplicates={checkForDuplicates}
                    hideDuplicateMessages={hideDuplicateMessages}
                    moveToFolder={moveToFolder}
                    createToDo={handleCreateTask}
                    settings={settings}
                    endDrop={endDrop}
                    style={
                      separator
                        ? {
                            ...style,
                            height:
                              (style.height as number) -
                              (theme.old.spacing.unit(2) +
                                theme.old.components.mailListItem.spacing),
                            top:
                              (style.top as number) +
                              (theme.old.spacing.unit(2) +
                                theme.old.components.mailListItem.spacing),
                          }
                        : style
                    }
                  />
                </>
              );
            }}
          />
        </div>
        <TasksDrawer
          visible={!!task}
          preSelectedTask={task}
          closeDrawer={() => setTask(null)}
        />
      </>
    );
  }
);

export default WidgetMailList;
