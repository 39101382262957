import { put, select } from 'redux-saga/effects';
import { CopyMessageWs } from '../../../models/Message';
import { notification } from 'antd';
import i18n from '../../../i18n';
import DuplicateMessageNotification from '../../documents/components/Notifications/DuplicateMessageNotification';

export declare type WSTypesEmailCopyEvent =
  | 'copySuccess'
  | 'copyFailure'
  | 'copyDuplicate';

export function* handleEmailCopyProgressMessage(data: any) {
  try {
    const objectType: CopyMessageWs = data.objectType;
    const messageId = objectType?.copyMessageDto?.messageId;
    yield put({
      type: 'COPY_MAIL_ASYNC',
      meta: {
        messageId: messageId,
      },
    });

    if (data.type === 'copyDuplicate' && !objectType.hideDuplicateMessages) {
      const projectId = yield select((state) => state.projects.activeProject);

      notification.open({
        message: i18n.t('mail:notification.duplicateMessageFound'),
        description: (
          <DuplicateMessageNotification
            copyMessageWs={objectType}
            projectId={projectId}
            targetProjectId={data.object}
            copiedMessageId={messageId}
          />
        ),
        key: `duplicateMessage-${messageId}`,
        duration: 30,
      });
    }
  } catch (innerError) {
    console.error('Error handling EmailCopyProgressMessage:', innerError, data);
  }
}
