import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { CopyMessageWs } from '../../../../models/Message';
import { apiCopyMessageToProject } from '../../../mail/api';
import { MessageId, ProjectId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DuplicateMessageNotificationProps {
  copyMessageWs: CopyMessageWs;
  projectId: ProjectId;
  targetProjectId: ProjectId;
  copiedMessageId: MessageId;
}

export const DuplicateMessageNotification: React.FC<
  DuplicateMessageNotificationProps
> = ({ copyMessageWs, projectId, copiedMessageId, targetProjectId }) => {
  const copyMessageDto = copyMessageWs.copyMessageDto;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [moveStatus, setMoveStatus] = useState<'success' | 'failure' | null>(
    null
  );

  const handleMoveMessage = async () => {
    setLoading(true);
    try {
      const response = await apiCopyMessageToProject(
        copiedMessageId,
        projectId,
        targetProjectId,
        copyMessageWs.messageFolderId,
        copyMessageDto.deleteMail,
        copyMessageDto.copyAttachments
      );
      setLoading(false);
      if (response.result.status === 200) {
        setMoveStatus('success');
      } else {
        setMoveStatus('failure');
      }
    } catch (error) {
      setLoading(false);
      setMoveStatus('failure');
    }
    setTimeout(() => {
      handleCloseNotification();
    }, 2000);
  };

  const handleCloseNotification = () => {
    notification.close(`duplicateMessage-${copyMessageDto.messageId}`);
  };

  return (
    <div>
      <p>{`${t('mail:notification.newMessageSubject')}: ${
        copyMessageWs.messageSubject
      }`}</p>
      {moveStatus === 'success' && (
        <div>
          <FontAwesomeIcon icon={['fal', 'check-circle']} color="green" />{' '}
          {t('mail:notification.moveSuccess')}
        </div>
      )}
      {moveStatus === 'failure' && (
        <div>
          <FontAwesomeIcon icon={['fal', 'times-circle']} color="red" />{' '}
          {t('mail:notification.moveFailure')}
        </div>
      )}
      {!moveStatus && (
        <>
          <Button onClick={handleCloseNotification}>
            {t('mail:notification.close')}
          </Button>
          <Button
            onClick={handleMoveMessage}
            type="primary"
            style={{ float: 'right' }}
            loading={loading}
          >
            {t('mail:notification.moveMessage')}
          </Button>
        </>
      )}
    </div>
  );
};

export default DuplicateMessageNotification;
