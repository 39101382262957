import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { SettingKey, UpdateSetting } from '../../../models/Settings';

export const FETCH_SETTING_REQUEST = PRIO + 'FETCH_SETTING_REQUEST';
export const FETCH_SETTING_COMMIT = PRIO + 'FETCH_SETTING_COMMIT';
export const FETCH_SETTING_ROLLBACK = PRIO + 'FETCH_SETTING_ROLLBACK';

export const fetchSetting = (SettingKey: SettingKey[]) => ({
  type: FETCH_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/GetSettings`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: SettingKey,
      },
      commit: { type: FETCH_SETTING_COMMIT, meta: { SettingKey } },
      rollback: {
        type: FETCH_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchSettingsError',
          timeout: 6,
        },
      },
    },
  },
});

export const UPDATE_USER_SETTING_REQUEST = PRIO + 'UPDATE_USER_SETTING_REQUEST';
export const UPDATE_USER_SETTING_COMMIT = PRIO + 'UPDATE_USER_SETTING_COMMIT';
export const UPDATE_USER_SETTING_ROLLBACK =
  PRIO + 'UPDATE_USER_SETTING_ROLLBACK';

export const updateUserSetting = (
  setting: UpdateSetting,
  userId: string,
  key: string
) => ({
  type: UPDATE_USER_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/user/${userId}/${key}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: setting,
      },
      commit: { type: UPDATE_USER_SETTING_COMMIT, meta: { setting } },
      rollback: {
        type: UPDATE_USER_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateSettingsError',
          timeout: 6,
        },
      },
    },
  },
});
